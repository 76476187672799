<template>
  <v-dialog
    v-model="dialog"
    :width="$vuetify.breakpoint.smAndDown ? '100%' : '30%'"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon style="height: 30px" small class="mr-2" v-on="on">
        <v-icon small> mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Añadir depósito </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="deposit-form"
        @submit.prevent="validateForm('deposit-form')"
      >
        <v-card-text>
          <v-row justify="center">
            <v-col cols="8" class="py-0">
              <label>{{ $t("expenses.pay_mode") }}</label>
              <v-select
                outlined
                dense
                hide-details
                v-model="formData.method"
                :items="methods"
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('deposit-form.method')"
                data-vv-name="method"
              ></v-select>
            </v-col>
            <v-col cols="8" class="py-0">
              <label>Cantidad</label>
              <v-text-field
                outlined
                dense
                hide-details
                v-model="formData.quantity"
                data-vv-validate-on="blur"
                v-validate="'required|min:2'"
                :error-messages="errors.collect('deposit-form.quantity')"
                data-vv-name="quantity"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="
              dialog = false;
              files = [];
            "
            style="height: 25px; width: 100px"
            elevation="0"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            @click="accion = 'send'"
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$send</v-icon>
            {{ $t("enviar", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "NewDeposit",
  props: ["appointment"],

  data() {
    return {
      dialog: false,
      formData: {},
      accion: "send",
      methods: [
        {
          text: this.$t("expenses.cash"),
          value: "cash",
        },
        {
          text: this.$t("expenses.card"),
          value: "card",
        },
      ],
    };
  },
  methods: {
    ...mapActions("appointments", ["addDeposit"]),
    async validateForm(scope) {
      this.errors.clear();
      let result = await this.$validator.validateAll(scope);
      if (result) {
        console.log("VALIDADO");
        this.addDeposit({
          appointment_id: this.appointment.id,
          formData: this.formData,
        }).then((response) => {
          this.$emit("update");
          this.dialog = false;
        });
      }
    },
  },
};
</script>
<style lang="sass" scoped>

label
  height: 25px
  display: block
</style>
